
import { getLatestTransaction } from "~/helpers/transaction-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";

export default {
  props: {
    referenceProperties: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getLatestTransaction: getLatestTransaction,

    getPropertyAddress: getPropertyAddress,

    getLatestTransactionHandoverData(property) {
      return this.getLatestTransaction(property)?.handoverDate;
    },

    getLatestTransactionPurchaseSummary(property) {
      return getLatestTransaction(property)?.transactionInfo?.totalPurchaseSum;
    },
  },
};
