// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_nsZE9{height:100%;margin-top:1rem}.container_nsZE9,.layout_juQvi{display:flex;flex-direction:column;width:100%}.cta_DS1wP,.layout_juQvi{justify-content:center}.cta_DS1wP{display:flex;margin-top:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_nsZE9",
	"layout": "layout_juQvi",
	"cta": "cta_DS1wP"
};
module.exports = ___CSS_LOADER_EXPORT___;
