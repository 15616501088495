
export default {
  props: {
    handoverDate: {
      type: String,
      required: true,
    },

    totalPurchaseSum: {
      type: Number,
      required: true,
    },

    transferType: {
      type: String,
      required: true,
    },

    transactionsCount: {
      type: Number,
      required: true,
    },
  },
};
