export const getTenantBreakdownByEmployees = function (addresses) {
    if (!addresses || addresses.length === 0) {
        return [];
    }

    const productionUnits = addresses
        .flatMap(address => address.tenants)
        .filter(tenant => tenant != null);

    const totalTenantEmployeeCount = productionUnits.reduce((a, b) => { return a + b.employeeCount }, 0);

    return productionUnits.map(productionUnit => {
        return {
            companyName: productionUnit.company.name,
            companyCvr: productionUnit.company.cvr,
            employeeCount: productionUnit.employeeCount,
            employeePercent: productionUnit.employeeCount / totalTenantEmployeeCount * 100
        }
    })
}
