// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_ZaDZv{flex-direction:column;height:100%}.container_ZaDZv,.layout_WN\\+Fw{display:flex;width:100%}.layout_WN\\+Fw{align-items:center;padding:0 1rem}.img_X6-iT{height:3.5rem}.copy_NJMQd{flex-direction:column;height:100%;justify-content:space-between;padding:1rem 0}.copy_NJMQd,.copyEnd_WHTdE,.copyStart_tSnRs{display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_ZaDZv",
	"layout": "layout_WN+Fw",
	"img": "img_X6-iT",
	"copy": "copy_NJMQd",
	"copyEnd": "copyEnd_WHTdE",
	"copyStart": "copyStart_tSnRs"
};
module.exports = ___CSS_LOADER_EXPORT___;
