
import { getPropertyOverviewQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import Overview from "../components/mainproperty/Overview.vue";

export default {
  title() {
    return `${this.$t("COMPANY_TAB_OVERVIEW")}`;
  },

  apollo: {
    property: getPropertyOverviewQuery,
  },

  components: {
    Overview,
  },

  computed: {
    loading: function () {
      return this.property == null || this.$apollo.queries.property.loading;
    },

    isProperty: function () {
      return this.property.plots != null;
    },
  },

  watch: {
    // condos do not have an overview tab so re-route to another tab
    property: function (val) {
      if (val && (val.condo != null || val.buildingOnForeignPlot != null)) {
        this.$router.replace({
          name: "explore-property-id-buildings",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
