export const getLatestTransaction = function (property) {
  const transaction = getLatestTransactions(property, 1);

  if (transaction.length === 0) {
    return null;
  } else {
    return transaction[0];
  }
};

export const getLatestTransactions = function (property, numberOfTransactions) {
  if (!property.ownerChanges || property.ownerChanges.length === 0) {
    return [];
  }

  const transactionsSorted = [...property.ownerChanges].sort((a, b) => new Date(b.handoverDate).getTime() - new Date(a.handoverDate).getTime());

  if (numberOfTransactions >= transactionsSorted.length) {
    return transactionsSorted;
  } else {
    return transactionsSorted.slice(0, numberOfTransactions);
  }
};
