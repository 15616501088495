
import { filter } from "~/composables/useFilter";

export default {
  props: {
    plans: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getPlanTooltipText(plan) {
      return `${plan.planName} - ${filter.date.short(plan.adoptionDate)}`;
    },
  },
};
