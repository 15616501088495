// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_KtlUh,.layout_yCMlQ{display:flex;flex-direction:column;height:100%;width:100%}.label_rcRzz{flex-direction:row;padding:1rem}.label_rcRzz,.table_UWs3k{display:flex;width:100%}.table_UWs3k{height:100%}.cta_5M5Pi,.table_UWs3k{flex-direction:column}.cta_5M5Pi{display:flex;justify-content:flex-end;padding:.5rem 1rem 1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_KtlUh",
	"layout": "layout_yCMlQ",
	"label": "label_rcRzz",
	"table": "table_UWs3k",
	"cta": "cta_5M5Pi"
};
module.exports = ___CSS_LOADER_EXPORT___;
